import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { Person } from "../../types";

export function PeopleList() {
  const res = useLoaderData() as any;
  const peoples = res.peoples || [];
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Surname</TableCell>
              <TableCell align="right">Skills</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {peoples.map((person: Person) => (
              <TableRow key={person.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/people/${person.id}`}>
                    {person.displayName}
                  </Link>
                </TableCell>
                <TableCell align="right">{person.surname}</TableCell>
                <TableCell align="right">
                  {person.skills.map((s) => s.skill).join(", ")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Link color="primary" href={`/people/${Date.now()}`} sx={{ mt: 3 }}>
        Create
      </Link>
    </>
  );
}
