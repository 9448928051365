import React from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
} from "@mui/material";
import { Form, useLoaderData } from "react-router-dom";
import { IdInput } from "../IdInput";

export function OKRForm() {
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  const empty = {
    Q1: "Increase the number of qualified leads by 25%.",
    Q2: "Close deals with 10 new enterprise clients.",
    Q3: "Increase the Average Contract Value (ACV) by 15%.",
    Q4: "Improve Customer Satisfaction Score (CSAT) by 10 points.",
  };
  const initialData = useLoaderData() as any;
  const [objective, setObjective] = React.useState(
    initialData?.objective ||
      "Increase Sales Efficiency and Expand Market Reach"
  );
  const [keyResult, setKeyResult] = React.useState(
    initialData?.keyResult || empty
  );
  const [keyProgress, setKeyProgress] = React.useState(
    initialData?.keyProgress || {}
  );

  const handleKeyResultChange = (quarter: any, value: any) => {
    setKeyResult((prevState: any) => ({
      ...prevState,
      ["keyResult" + quarter]: value,
    }));
  };
  const handleKeyProgressChange = (quarter: any, value: any) => {
    setKeyProgress((prevState: any) => ({
      ...prevState,
      ["keyResultProgress" + quarter]: value,
    }));
  };
  return (
    <Paper elevation={3} style={{ padding: "16px" }}>
      <Form method="post">
        {IdInput(Date.now())}
        <TextField
          name="objective"
          label="Objective"
          value={objective}
          onChange={(e) => setObjective(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        {quarters.map((q, ikey) => (
          <Grid container spacing={3} key={ikey}>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <InputLabel id={`${q}-label`}>Quarter</InputLabel>
                <Select labelId={`${q}-label`} value={q}>
                  <MenuItem value={q} key={q}>
                    {q}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg>
              <TextField
                name={`keyResult${q}`}
                label="Key Result"
                value={keyResult[q]}
                onChange={(e) => handleKeyResultChange(q, e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item sm>
              <TextField
                name={`keyResultProgress${q}`}
                label="Progress"
                value={keyProgress[q]}
                onChange={(e) => handleKeyProgressChange(q, e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        ))}
        <Button variant="contained" color="primary" type="submit">
          Add OKR
        </Button>
      </Form>
    </Paper>
  );
}
