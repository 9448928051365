import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import { useLoaderData } from "react-router-dom";
import { PieChart } from "@mui/x-charts";

export default function OkrsDashboard() {
  const res = useLoaderData() as any;
  console.log("res", res);
  const rows = res.okrs;
  return (
    <React.Fragment>
      <Title>Current OKRs</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Ojective</TableCell>
            <TableCell>Key Result</TableCell>
            <TableCell colSpan={2} align="right">Progress</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) =>
            ["Q1", "Q2", "Q3", "Q4"].map((q: any) => {
              const progress = row[`keyResultProgress${q}`];
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.objective}</TableCell>
                  <TableCell>{row[`keyResult${q}`]}</TableCell>
                  <TableCell align="right">{`${
                    row[`keyResultProgress${q}`]
                  }%`}</TableCell>
                  <TableCell>
                    <PieChart
                      legend={{
                        hidden: true,
                      }}
                      series={[
                        {
                          data: [
                            {
                              id: 0,
                              value: progress,
                              label: "l1",
                            },
                            {
                              id: 0,
                              value: 100 - progress,
                              label: "l2",
                              color: "lightgray",
                            },
                          ],
                        },
                      ]}
                      width={200}
                      height={200}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Link color="primary" href="/okrs" sx={{ mt: 3 }}>
        See more
      </Link>
      <Link color="primary" href="/okrs/create" sx={{ mt: 3 }}>
        Create
      </Link>
    </React.Fragment>
  );
}
