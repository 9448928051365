import React, { useState } from "react";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { useLoaderData } from "react-router-dom";
import { Skill } from "../../types";

interface SkillsFormProps {
  onChange: (skills: Skill[]) => void;
}

const SkillsForm: React.FC<SkillsFormProps> = ({ onChange }) => {
  const res = useLoaderData() as any;

  const [skills, setSkills] = useState<Skill[]>([]);
  const options = res.skills;

  function handleSkillChange(id: number, value: Skill | null) {
    if (value) {
      setSkills((prevSkills) => {
        return prevSkills.map((skill) => (skill.id === id ? value : skill));
      });
      onChange(skills);
    }
  }

  const handleAddSkill = () => {
    setSkills((prevSkills) => [
      ...prevSkills,
      { id: Date.now(), skillId: 0, skill: "" },
    ]);
    onChange(skills)
  };

  const handleRemoveSkill = (id: number) => {
    setSkills((prevSkills) => prevSkills.filter((skill) => skill.id !== id));
    onChange(skills)
  };

  return (
    <Grid container spacing={2}>
      {skills.map((skill, index) => (
        <Grid item xs={12} key={skill.id}>
          <Grid container>
            <Grid item xs={11}>
              <Autocomplete
                options={options}
                getOptionLabel={(option) => option.skill}
                value={skills[index]}
                onChange={(_, newValue) =>
                  handleSkillChange(skill.id, newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} label={`Skill`} />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => handleRemoveSkill(skill.id)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          onClick={handleAddSkill}
          startIcon={<AddCircleOutlineIcon />}
          color="primary"
        >
          Add Skill
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" variant="contained" color="primary">
          Save skills
        </Button>
      </Grid>
    </Grid>
  );
};

export default SkillsForm;
