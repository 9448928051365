import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useState } from "react";
import { postData } from "../repository";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}

    >
      <div>
        <Link color="inherit" href="https://pathprogress.com/about">
          About Us
        </Link>
      </div>
      <div>
        {"Copyright © "}
        <Link color="inherit" href="https://pathprogress.com">
          PathProgress
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </div >
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignIn(props: any) {
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("email")!.toString();
    const password = data.get("password")!.toString();
    try {
      const response = await postData("/is-authenticated", {
        username,
        password,
      });

      if (response.ok) {
        setError("");
        // const mm = dayjs().format("MM");
        localStorage.setItem(`user.username`, username);
        localStorage.setItem(`user.password`, password);
        props.onLoginSuccess();
      } else {
        setError("Something went wrong, please try again.");
      }
    } catch (err: any) {
      if (err.response && err.response.status === 400) {
        setError("Invalid email or password.");
      } else {
        setError("Something went wrong, please try again.");
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <Typography component="h1" variant="h6">
            PathProgress
          </Typography>
          <br />
          <img src="logo_pp.png" alt="Sign In" style={{ width: '100px' }} />
          <br />
          <Typography component="h1" variant="h6">
            Picture the Future, Plan the Path.
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container visibility={"hidden"}>
              <Grid item xs>
                <Link href="#" variant="body2" >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Registration is available by invitation only"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
