import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation

// Create a new theme or use the default one
const theme = createTheme();

export function AboutPage() {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <br/>
        <Typography variant="h3" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to our platform where mentees can find human mentors and AI mentors. Our goal is to provide a diverse range of mentorship options to help individuals grow personally and professionally.
        </Typography>
        <Typography variant="body1" paragraph>
          Whether you're seeking guidance in a specific field, looking for career advice, or interested in personal development, our platform connects you with experienced mentors who can support you on your journey.
        </Typography>
        <Typography variant="body1" paragraph>
          Our team is committed to fostering a supportive and inclusive community where mentees can thrive. We believe that mentorship is a powerful tool for learning and growth, and we're excited to be part of your journey towards success.
        </Typography>
        <Typography variant="body1" paragraph>
          This platform was founded by Anatoli Klamer and Francine Kite. Anatoli serves as the CTO, and Francine as the CEO.
        </Typography>
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src="https://media.licdn.com/dms/image/D5603AQEe7Qc4FnLaVw/profile-displayphoto-shrink_800_800/0/1682508222184?e=1721865600&v=beta&t=pYkZnLYYTJ_KDe-KZFNV22dz5IODeA_kO3tMuWXvgIw"
            alt="Francine Kite"
            style={{ width: '150px', borderRadius: '50%', marginRight: '20px' }}
          />
          <Typography variant="body1">
            <strong>Francine Kite - CEO</strong><br />
            I have over a decade of comprehensive HR experience spanning the entire employee lifecycle, from recruitment and retention to talent advancement. My expertise thrives within the dynamic realm of tech startups and disruptors, where I've spearheaded rapid team and capability growth, infusing innovative practices into established structures.
          </Typography>
        </div>
        <br />
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <img
            src="https://media.licdn.com/dms/image/D5603AQH6BawyaD9Jdw/profile-displayphoto-shrink_800_800/0/1713422574163?e=1721865600&v=beta&t=_SoSfDj5QNkZtIvd1Bs3agGxayhrnMPzkcH2DkUpJjQ"
            alt="Anatoli Klamer"
            style={{ width: '150px', borderRadius: '50%', marginRight: '20px' }}
          />
          <Typography variant="body1">
            <strong>Anatoli Klamer - CTO</strong><br />
            I bring a wealth of experience as a seasoned software engineer with a passion for leveraging technology to drive positive change. With a career spanning multiple industries and projects, I've honed my skills in developing scalable and innovative solutions that empower individuals and organizations to achieve their goals.
          </Typography>
        </div>


        <Typography variant="body1">
          If you have any questions or feedback, please don't hesitate to <Link to="https://www.linkedin.com/in/anatoli-klamer/">contact us</Link>. We're here to help!
        </Typography>
      </Container>
    </ThemeProvider>
  );
}
