import axios from "axios";
const baseURL = process.env.REACT_APP_BACKEND_API_URL;
const client = axios.create({
  baseURL,
  withCredentials:true
});

export async function getData(url: string, params: any) {
  return (await client.get(url, params)).data;
}

export async function postData(url: string, data: any) {
  return (await client.post(url, data)).data;
}
