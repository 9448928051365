import { Root } from "./components/root/Root";
import { createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "./components/ErrorPage/ErrorPage";

import Dashboard from "./components/dashboard/Dashboard";
import { getData, postData } from "./repository";
import { OKRList } from "./components/okr-list/OKRList";
import { OKRForm } from "./components/OKRForm/OKRForm";
import { PeopleList } from "./components/PeopleList/PeopleList";
import { PersonForm } from "./components/PeopleList/PersonForm";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
        loader: async () => {
          return await getData("/okrs", {});
        },
      },
      {
        path: "okrs",
        element: <OKRList />,
        loader: async () => {
          return await getData("/okrs", {});
        },
      },
      {
        path: "okrs/create",
        element: <OKRForm />,
        action: async ({ params, request, context }) => {
          let formData = await request.formData();
          //var formData = new FormData(target);
          formData.forEach((v, k) => {
            console.log("f", { v, k });
          });

          return postData("/okrs", formData);
        },
      },
      {
        path: "okrs/:id",
        element: <OKRForm />,
        loader: async ({ params }) => {
          return await getData(`/okrs/${params.id}`, {});
        },
        action: async ({ params, request, context }) => {
          let formData = await request.formData();
          return postData("/okrs", formData);
        },
      },
      {
        path: "people",
        element: <PeopleList />,
        loader: async ({ params }) => {
          return await getData(`/people`, {});
        },
      },
      {
        path: "people/:personId",
        element: <PersonForm />,
        loader: async ({ params }) => {
          const { skills } = await getData(`/skills`, {});
          const { people } = await getData(`/people/${params.personId}`, {});
          return { skills, people };
        },
        action: async ({ params, request, context }) => {
          let formData = await request.formData();
          return postData("/people", formData);
        },
      },
    ],
  },
]);
