import { useEffect, useState } from "react";
import SignIn from "./components/SignIn";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter as Router, Link, Route, RouterProvider, Routes } from "react-router-dom";

import { postData } from "./repository";
import { router } from "./router";
import { AboutPage } from "./components/About";

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState(true); // to handle loading state

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const username = localStorage.getItem("user.username");
        const password = localStorage.getItem("user.password");
        const response = await postData("/is-authenticated", {
          username,
          password,
        });

        if (response.ok) {
          setIsSignedIn(true);
        }
      } catch (err) {
        setIsSignedIn(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!isSignedIn) {
    return (
      <Router>
        <Routes >
          <Route path="/about" element={<AboutPage />} />
          <Route index path="/" element={<SignIn onLoginSuccess={() => setIsSignedIn(true)} />} />
        </Routes>
      </Router>
    )
  }
  return <RouterProvider router={router} />;
}

export default App;
