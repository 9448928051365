import { Grid, Link, Paper } from "@mui/material";

import { useLoaderData } from "react-router-dom";
import Title from "./Title";
import { PieChart } from "@mui/x-charts";

export function OKRList() {
  const res = useLoaderData() as any;
  const okrs = res.okrs;

  return (
    <>
      <Title>Objectives and Key Results</Title>

      {okrs.map((okr: any, index: number) => (
        <Paper elevation={3} style={{ margin: "16px 0", padding: "16px" }}>
          <Grid container spacing={3}>
            <Grid item>{okr.objective}</Grid>
            <Grid item>{okr.keyResultQ1}</Grid>
            <Grid item>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: okr.keyResultProgressQ1, label: okr.keyResultQ1 },
                      { id: 1, value: okr.keyResultProgressQ2, label: okr.keyResultQ2 },
                      { id: 2, value: okr.keyResultProgressQ3, label: okr.keyResultQ3 },
                      { id: 3, value: okr.keyResultProgressQ4, label: okr.keyResultQ4 },
                    ],
                  },
                ]}
                width={800}
                height={300}
              />
            </Grid>
          </Grid>
        </Paper>
      ))}

      <Link color="primary" href="/okrs/create" sx={{ mt: 3 }}>
        Create
      </Link>
    </>
  );
}
