import { Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";

export function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Typography variant="h1" gutterBottom align="center">
        Oops!
      </Typography>
      <Typography variant="h2" gutterBottom align="center">
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography variant="h5" style={{ color: "red" }} align="center">
        {error.statusText || error.message}
      </Typography>
    </div>
  );
}
