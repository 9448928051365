import { FormEvent, useState } from "react";
import { Button, TextField, Paper, Grid, Box } from "@mui/material";
import { Form, Outlet, useParams } from "react-router-dom";
import { IdInput } from "../IdInput";
import SkillsForm from "./SkillsForm";
import { Skill } from "../../types";
import { postData } from "../../repository";

export function PersonForm() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [details, setDetails] = useState("");
  const params = useParams();
  const onSubmit = (skills: Skill[]) => {
    //await postData
  };
  function onSubmitPerson(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    //await postData
  }

  return (
    <Paper style={{ padding: 16 }}>
      <Form onSubmit={onSubmitPerson}>
        {IdInput(Date.now())}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Surname"
              fullWidth
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Display Name"
              fullWidth
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              placeholder={`${name} ${surname}`}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Details"
              fullWidth
              multiline
              rows={3}
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>

          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Form>
      <Box />
      <SkillsForm onChange={onSubmit} />
    </Paper>
  );
}
